.mrc-social-media-card {
  @apply flex w-full flex-col items-center justify-center gap-4 rounded-2xl border p-6 font-normal sm:justify-between sm:gap-8;

  .mrc-social-links {
    @apply grid grid-cols-6 gap-4;

    button {
      @apply inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800;

      .social-icon {
        @apply h-4 w-4 text-inherit;
      }
    }
  }

  .card-header {
    @apply text-xl font-bold text-gray-900 sm:text-2xl;
  }

  .card-footer {
    @apply border-t pt-4 text-base text-zinc-600 sm:text-base;
  }
}

.social-footer.mrc-social-links {
  font-size: 1rem;
  @apply inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 hover:text-gray-800;
}
