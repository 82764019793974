.mrc-contact-us-card {
  @apply flex w-full flex-col items-center gap-4 rounded-2xl border p-6 font-normal sm:gap-8;

  .card-header {
    @apply text-xl font-bold text-gray-900 sm:text-2xl;
  }

  .card-footer {
    @apply inline-flex max-w-max rounded-full bg-primary px-5 py-2 text-base text-white hover:bg-primary-dark hover:text-white;
  }

  .card-body {
    @apply flex flex-col gap-1 text-base sm:gap-2 sm:text-base;
  }
}
